<template>
  <a-drawer width="1000" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>绑定设备</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <template>
              <a-col :md="8" :sm="24">
                <a-form-item label="设备名称" prop="valveName">
                  <a-input v-model="queryParam.valveName" placeholder="请输入" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="设备mac地址" prop="valveMac">
                  <a-input v-model="queryParam.valveMac" placeholder="请输入" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="项目" prop="projectId">
                  <a-select v-model="queryParam.projectId" placeholder="请选择项目" allow-clear>
                    <a-select-option v-for="(item,key) in projectList" :key="key" :value="item.id">{{ item.projectName }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-right: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        size="small"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false">
        <span slot="pic" slot-scope="text, record">
          <img style="width:50px;heigth:50px" :src="record.imgUrl" />
        </span>
        <span slot="status" slot-scope="text, record">
          <a-badge color="red" text="禁用" v-if="record.status == 0"/>
          <a-badge color="cyan" text="启用" v-if="record.status == 1"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize" />
    </a-card>
    <div style="width: 100%;display: flex;justify-content: flex-end;">
      <a-button style="margin-right: 20px;" type="primary" :loading="submitLoading" @click="submitForm" :disabled="multiple">
        确认绑定
      </a-button>
      <a-button type="dashed" @click="cancel">
        取消
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import { listProject } from '@/api/valve/project'
import { pageDevice } from '@/api/valve/device'
import { bindUser } from '@/api/user/user'

export default {
  name: 'User',
  props: {
  },
  data() {
    return {
      uid: null,
      projectList: [],
      open: false,
      submitLoading: false,

      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        valveName: undefined,
        valveMac: undefined,
        projectId: undefined,
        pageNum: 1,
        pageSize: 10
      },
      // 非多个禁用
      multiple: true,
      selectedRowKeys: [],
      selectedRows: [],
      list: [],
      columns: [
        {
          title: '设备名称',
          dataIndex: 'valveName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备mac地址',
          dataIndex: 'valveMac',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '项目名称',
          dataIndex: 'projectName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备封面',
          dataIndex: 'imgUrl',
          ellipsis: true,
          scopedSlots: { customRender: 'pic' },
          align: 'center'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.queryParam = {
      valveName: undefined,
      valveMac: undefined,
      projectId: undefined,
      pageNum: 1,
      pageSize: 10
    }
    listProject().then(res => {
      this.projectList = res.data
    })
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    /** 查询用户信息列表 */
    bdList(id) {
      this.uid = id
      this.open = true
      this.loading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      this.list = []
      if (id) {
        pageDevice({ userId: id, pageNum: 1, pageSize: 999999 }).then(res => {
          const ulist = res.data.records
          if (ulist && ulist.length > 0) {
            this.selectedRowKeys = ulist.map(item => item.id)
            this.selectedRows = ulist
            this.multiple = !ulist.length
          }
        })
      }
      pageDevice(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.bdList(this.uid)
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        valveName: undefined,
        valveMac: undefined,
        projectId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.bdList(this.uid)
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.bdList(this.uid)
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    /** 提交按钮 */
    submitForm: function () {
      // this.submitLoading = true
      bindUser({
        id: this.uid,
        devIds: this.selectedRowKeys
      }).then(response => {
        this.$message.success(
          '绑定成功',
          3
        )
        this.open = false
        this.$emit('ok')
      }).finally(() => {
        this.submitLoading = false
      })
    }
  }
}
</script>
